/* eslint-disable @typescript-eslint/no-unused-vars */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  CreateMealPayloadProps,
  FormDataProps,
  GetMealsParamsInterface,
} from "../../../pages/restaurant-portal/restaurant.config";
import { RootState } from "../../../app/store";

export const restaurantAuthApi = createApi({
  reducerPath: "restaurantAuthApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    restaurantLogin: builder.mutation({
      query: (body: { email: string; password: string }) => {
        return {
          url: "/restaurants/login",
          method: "post",
          body,
        };
      },
    }),
    restaurantSignup: builder.mutation({
      query: (body: FormDataProps) => {
        return {
          url: "/restaurants",
          method: "post",
          body,
        };
      },
    }),
  }),
});

export const { useRestaurantLoginMutation, useRestaurantSignupMutation } =
  restaurantAuthApi;

export const restaurantApi = createApi({
  reducerPath: "restaurantApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    credentials: "include",
    // mode: "cors",

    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).restaurantAuth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", `application/json`);
      }

      return headers;
    },
  }),

  endpoints: (builder) => ({
    getActivationCode: builder.mutation({
      query: (body: { email: string }) => {
        return {
          url: "/restaurants/activation-code",
          method: "post",
          body,
        };
      },
    }),
    activateAccount: builder.mutation({
      query: (body: any) => {
        return {
          url: "/restaurants/activate-account",
          method: "patch",
          body,
        };
      },
    }),
    getAllOrders: builder.query({
      query: (params: { page: number; order_status?: string }) => {
        return {
          url: `/shopping/restaurant/orders?page=${params.page}${
            params.order_status ? `&order_status=${params.order_status}` : ""
          }`,
          method: "GET",
          // params,
        };
      },
    }),

    createMeal: builder.mutation({
      query: (body: CreateMealPayloadProps) => {
        return {
          url: "/meals",
          method: "POST",
          body,
        };
      },
    }),
    updateMeal: builder.mutation({
      query: (data: any) => {
        return {
          url: `/meals/update/${data?.meal_id}`,
          method: "PATCH",
          body: data?.body,
        };
      },
    }),
    deleteMeal: builder.mutation({
      query: (meal_id: any) => {
        return {
          url: `/meals/delete/${meal_id}`,
          method: "DELETE",
        };
      },
    }),
    uploadMealImage: builder.mutation<any, FormData>({
      query: (image) => ({
        url: "/image-upload",
        method: "POST",
        body: image,
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      }),
    }),
    //I set the returnvaluetype and argument type to void because there's no argument
    // type any should be replaced with the profile type
    getRestaurantProfile: builder.query<any, void>({
      query: () => {
        return {
          url: "/restaurants/profile",
          method: "GET",
        };
      },
    }),

    updateRestaurantProfile: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: "/restaurants/update",
          method: "PATCH",
          body,
        };
      },
    }),

    // type any should be replaced with the mealsCategories type
    getMealsCategories: builder.query<any, void>({
      query: () => {
        return {
          url: "/meals/categories",
          method: "GET",
        };
      },
    }),
    // type any should be replaced with the meals type
    // url: `/meals/restaurant?page=${params.page}`,
    // url: `/meals/search?page=${params.page}&meal_category=${params.meal_category}`,
    getMeals: builder.query({
      query: (params) => {
        return {
          url: `/meals/restaurant?page=${params.page}&meal_category=${params.meal_category}`,
          method: "GET",
          params,
        };
      },
    }),

    getRiderDetails: builder.query({
      query: (params) => {
        return {
          url: `/agents/${params.agent_id}`,
          method: "GET",
          params,
        };
      },
    }),

    getSingleMeal: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/meals/${params}`,
          method: "GET",
          params,
        };
      },
    }),

    getRestaurantReviews: builder.query({
      query: (params: any) => {
        return {
          url: `/restaurants/reviews/${params.restaurant_id}${
            params?.page ? `&page=${params?.page}` : ""
          }`,
          method: "GET",

          params,
        };
      },
    }),

    createMealCategory: builder.mutation({
      query: (body) => {
        return {
          url: "/meals/category/create",
          method: "POST",
          body,
        };
      },
    }),

    getAvailableRiders: builder.mutation({
      query: (body) => {
        return {
          url: `/shopping/delivery-request`,
          method: "POST",
          body,
        };
      },
    }),

    declineMealOrder: builder.mutation({
      query: (params) => {
        return {
          url: `/shopping/order/decline-order/${params}`,
          method: "PATCH",
          params,
        };
      },
    }),
    acceptMealOrder: builder.mutation({
      query: (order_id) => {
        return {
          url: `/shopping/update/order/${order_id}`,
          method: "PATCH",
          body: {
            order_status: "confirmed",
          },
        };
      },
    }),

    getRefreshToken: builder.query({
      query: () => {
        return {
          url: "/restaurants/refresh-token", //replace with actual endpoint also confirm the method
          method: "GET",
        };
      },
    }),
    getWalletBal: builder.query<any, void>({
      query: () => {
        return {
          url: "/restaurants/wallet",
          method: "GET",
        };
      },
    }),
    getBankDetails: builder.query<any, void>({
      query: () => {
        return {
          url: "/restaurants/bank-details",
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetActivationCodeMutation,
  useActivateAccountMutation,
  useLazyGetAllOrdersQuery,
  useGetAllOrdersQuery,
  useUploadMealImageMutation,
  useCreateMealMutation,
  useLazyGetRestaurantProfileQuery,
  useGetMealsCategoriesQuery,
  useCreateMealCategoryMutation,
  useLazyGetMealsQuery,
  useLazyGetSingleMealQuery,
  useUpdateMealMutation,
  useDeleteMealMutation,
  useUpdateRestaurantProfileMutation,
  useLazyGetRestaurantReviewsQuery,
  useGetAvailableRidersMutation,
  useLazyGetRefreshTokenQuery,
  useDeclineMealOrderMutation,
  useAcceptMealOrderMutation,
  useLazyGetRiderDetailsQuery,
  useLazyGetWalletBalQuery,
  useLazyGetBankDetailsQuery
} = restaurantApi;

/* 
mlh36239@vogco.com

Pa$$w0rd!
*/
