/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { RootState, store } from "./app/store";
import { toast, ToastContainer } from "react-toastify";
import { routes } from "./utils/data/routes";
// import { getMessaging, getToken }from "firebase/messaging";
// import io, { Socket } from 'socket.io-client';
import "./assets/css/globalStyle.scss";
import "./assets/css/restaurant-portal/sign-in.scss";
import "./assets/css/restaurant-portal/sign-up.scss";
import "./assets/css/restaurant-portal/dashboard.order-management.scss";
import "./assets/css/restaurant-portal/dashboard.profile.scss";
import "./assets/css/restaurant-portal/dashboard.profile.edit.scss";
import "./assets/css/restaurant-portal/dashboard.reviews.scss";
import "./assets/css/restaurant-portal/dashboard.menu-manager.scss";
import "./assets/css/restaurant-portal/dashboard.add-menu.scss";
import "./assets/css/restaurant-portal/dashboard.acct-activation.scss";
import "./assets/css/restaurant-portal/dashboard.notification.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
// import PushNotification from "./components/notificationComponent/pushNotification";
// import { useLazyGetRefreshTokenQuery } from "./features/services/restaurant/service";
import axios from "axios";
import {
  setUser,
  toggleBankModal,
} from "./features/slice/restaurant/authSlice";
import {
  MDBBtn,
  MDBCollapse,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBSpinner,
  // MDBSelect,
} from "mdb-react-ui-kit";
import { CustomToolTip } from "./components";
import { useLazyGetBankDetailsQuery } from "./features/services/restaurant/service";

const router = createBrowserRouter(routes);
// Login details to test restaurant
//mlh36239@vogco.com
//Pa$$w0rd!

function AppWrapper() {
  const rToken = sessionStorage.getItem("PEAKREACH_REFRESH");
  const dispatch = useDispatch();
  const bankModal = useSelector(
    (state: RootState) => state.restaurantAuth.bankModal
  );

  // Add state for form data
  const [bankDetails, setBankDetails] = useState({
    bankName: "",
    accountNumber: "",
    accountName: "",
    accountType: "nuban",
  });

  const [getBankDetails, { data: existingBankDetails, isLoading: loading }] =
    useLazyGetBankDetailsQuery();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getBankDetails();
  }, []);

  useEffect(() => {
    setBankDetails({
      bankName: existingBankDetails?.bank_details?.bank || "",
      accountNumber: existingBankDetails?.bank_details?.account_number || "",
      accountName: existingBankDetails?.bank_details?.account_name || "",
      accountType: existingBankDetails?.bank_details?.account_type || "nuban",
    });
  }, [existingBankDetails]);

  // Add form submission handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    const token = localStorage.getItem("PEAKREACH_TOKEN");

    try {
      const payload = {
        account_type: bankDetails.accountType.toLowerCase(),
        account_number: bankDetails.accountNumber.trim(),
        account_name: bankDetails.accountName.trim().toLowerCase(),
        bank: bankDetails.bankName.trim().toLowerCase(),
      };

      const response = await axios.patch(
        `https://api.peakreachdelivery.com/api/v1/restaurants/update/bank-details`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Bank details updated successfully");
        dispatch(toggleBankModal(false));
        getBankDetails(); // Refresh bank details after update
      }
    } catch (error: any) {
      console.error("Error updating bank details:", error);
      const errorMessage =
        error.response?.data?.message || "Failed to update bank details";
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const refetchToken = () => {
    try {
      axios
        .get(
          `
        ${process.env.REACT_APP_BACKEND_BASE_URL}/restaurants/refresh-token`,
          { headers: { Authorization: `Bearer ${rToken}` } }
        )
        .then((res) => {
          dispatch(
            setUser({
              token: res.data.token,
              message: "token refetch successful",
            })
          );
        });
    } catch (err) {}
  };

  useEffect(() => {
    // Call the function immediately when the component mounts

    // Set up an interval to call the function every 1 hour (3600000 milliseconds)
    const intervalId = setInterval(() => {
      refetchToken();
    }, 3600000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <RouterProvider router={router} />
      {/* Bank Details Modal */}
      <div>
        <MDBModal show={bankModal} tabIndex="-1" className="bank-details-modal">
          <MDBModalDialog
            style={{
              maxWidth: "500px",
              margin: "1.75rem auto",
            }}
          >
            <MDBModalContent className="border-0 shadow-sm">
              <MDBModalBody className="p-4">
                <form
                  onSubmit={handleSubmit}
                  className="bank-details-container"
                >
                  <div className="header mb-4">
                    <h4 className="fw-bold mb-0 d-flex align-items-center">
                      Payment Details
                      <CustomToolTip
                        message="All fields marked * are required"
                        color="#FFAE0A"
                        size={15}
                      />
                    </h4>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="bank_name" className="fw-semibold mb-2">
                      Name of Bank<span className="text-danger">*</span>
                    </label>
                    <MDBInput
                      id="bank_name"
                      type="text"
                      tabIndex={5}
                      className="bg-light"
                      value={bankDetails.bankName}
                      onChange={(e) =>
                        setBankDetails({
                          ...bankDetails,
                          bankName: e.target.value,
                        })
                      }
                      required
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="account_type" className="fw-semibold mb-2">
                      Account Type<span className="text-danger">*</span>
                    </label>
                    <select
                      id="account_type"
                      className="form-select bg-light"
                      value={bankDetails.accountType}
                      onChange={(e) =>
                        setBankDetails({
                          ...bankDetails,
                          accountType: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="nuban">NUBAN</option>
                      <option value="savings">Savings</option>
                      <option value="current">Current</option>
                    </select>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="acct_no" className="fw-semibold mb-2">
                      Account Number<span className="text-danger">*</span>
                    </label>
                    <MDBInput
                      id="acct_no"
                      type="text"
                      tabIndex={6}
                      maxLength={10}
                      className="bg-light"
                      value={bankDetails.accountNumber}
                      onChange={(e) =>
                        setBankDetails({
                          ...bankDetails,
                          accountNumber: e.target.value,
                        })
                      }
                      required
                    />
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="acct_name" className="fw-semibold mb-2">
                      Account Name<span className="text-danger">*</span>
                    </label>
                    <MDBInput
                      id="acct_name"
                      type="text"
                      tabIndex={7}
                      minLength={3}
                      className="bg-light"
                      value={bankDetails.accountName}
                      onChange={(e) =>
                        setBankDetails({
                          ...bankDetails,
                          accountName: e.target.value,
                        })
                      }
                      required
                    />
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <MDBBtn
                      type="button"
                      color="secondary"
                      className="px-4"
                      disabled={isSubmitting}
                      onClick={() => {
                        setBankDetails({
                          bankName: "",
                          accountNumber: "",
                          accountName: "",
                          accountType: "nuban",
                        });
                        dispatch(toggleBankModal(false));
                      }}
                    >
                      Cancel
                    </MDBBtn>
                    <MDBBtn
                      type="submit"
                      className="px-4"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          <MDBSpinner size="sm" className="me-2" />
                        </>
                      ) : (
                        "Update"
                      )}
                    </MDBBtn>
                  </div>
                </form>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    </>
  );
}

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
      <ToastContainer hideProgressBar={true} position="top-right" />
      {/* <PushNotification /> */}
    </React.StrictMode>
  );
}

export default App;
