/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MDBBtn, MDBContainer, MDBInput } from "mdb-react-ui-kit";

import { Stages } from "../../../components";
import { AccountActivationProps } from "../restaurant.config";
import { useActivateAccountMutation } from "../../../features/services/restaurant/service";
import { toast } from "react-toastify";
import axios from "axios";

const ActivationCode = (props: AccountActivationProps) => {
  const navigate = useNavigate();
  const { index, steps, setIndex, formData, setFormData } = props;
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);

  const [activateAccount, { isError: activate_account_failed }] =
    useActivateAccountMutation();

  const handleChecked = () => setChecked((prevState: boolean) => !prevState);
  const handleActvCode = (e: any) =>
    setFormData((prevData) => ({
      ...prevData,
      activation_code: e.target.value,
    }));
  const handleSignatory = (e: any) =>
    setFormData((prevData) => ({
      ...prevData,
      signatory: e.target.value.toLowerCase(),
    }));

  const ACCESS_TOKEN = localStorage.getItem("PEAKREACH_TOKEN");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      formData.signatory === "" ||
      formData.signatory.toLowerCase() !==
        formData.business_Legal_name.toLowerCase()
    ) {
      setError(true);
      return;
    }
    if (formData.activation_code === "") {
      toast.error("Activation code field is required!");
      return;
    }

    if (formData.activation_code && checked && formData.signatory !== "") {
      setError(false);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESS_TOKEN}`, // Include authorization token if needed
      };
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/restaurants/activate-account`,
          JSON.stringify(formData),
          { headers }
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success(response?.data?.message);
            navigate("/restaurant-portal/signin");
          } else {
            toast.error("Error: could not activate account, try again!");
          }
        })
        .catch((error) => {
          // toast.error(`${error.message}: try again!`);
          toast.error(`Invalid code: try again!`);
        });

      activateAccount(formData);
    }
  };

  // useEffect(() => {
  //   if(activate_account_success){
  //     toast.success('Account activated!')
  //     navigate('/restaurant-portal/dashboard');
  //   }
  //   if(activate_account_failed){
  //     toast.error('Error: could not activate account, try again!');
  //   }

  // }, [activate_account_failed, activate_account_success])

  return (
    <div>
      <MDBContainer className="account-activation">
        <h1>Account Activation</h1>
        <Stages steps={steps} step={index} />
        <form className="activation-code" onSubmit={handleSubmit}>
          <div>
            <MDBInput
              type="text"
              label="Activation Code"
              size="lg"
              required
              value={formData.activation_code || ""}
              onChange={handleActvCode}
            />
          </div>

          <div className="t_c_block mt-4">
            <input
              type="checkbox"
              color="red"
              id="t_&_c"
              checked={checked}
              onChange={handleChecked}
            />
            &nbsp;
            <label htmlFor="t_&_c">
              I agree to the{" "}
              <a href="/terms-and-conditions" target="_blank">
                terms and conditions
              </a>
              of this business
            </label>
          </div>
          <div className="sign-block mt-3">
            I{" "}
            <input
              type="text"
              onChange={handleSignatory}
              value={formData.signatory || ""}
              className="signatory-input"
            />
            , agree to the terms of this contract and wish to continue. <br />
            {error && (
              <small className="error-text">
                Signatory name is required! & must tally with name of legal
                entity
              </small>
            )}
          </div>

          <div className="form-btns mt-5">
            <MDBBtn className="back-btn" onClick={() => setIndex(index - 1)}>
              &larr; Back
            </MDBBtn>
            <MDBBtn className="proceed-btn" type="submit">
              Activate Account &rarr;
            </MDBBtn>
          </div>
        </form>
      </MDBContainer>
    </div>
  );
};

export default ActivationCode;
