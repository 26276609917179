/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect, SetStateAction, Dispatch } from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import { AddAPhotoOutlined, Close } from "@mui/icons-material";
import axios from "axios";

import { AuthLayout } from "../../../components";
import { FormDataProps } from "../restaurant.config";
import Loader from "../../../components/loader";

const data = {
  headingText: "Hello there!",
  subText:
    "Create an account with us and we'll help you reach a wider audience and boost your sales. If you already have an account with us, sign in here.",
  btnText: "SIGN IN",
  btnPath: "/restaurant-portal/signin",
};

type Props = {
  index: number;
  setIndex: Dispatch<SetStateAction<number>>;
  formData: FormDataProps;
  signupLoading: boolean;
  setFormData: Dispatch<SetStateAction<FormDataProps>>;
  signUp: () => void;
};

export const SignUpImageUpload = (props: Props) => {
  const { index, setIndex, formData, setFormData, signUp, signupLoading } =
    props;
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [uploadedImage, setUploadedImage] = useState(formData.image_url || "");
  const [displayedImage, setDisplayedImage] = useState(
    formData.image_url || ""
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // when 'isSuccess' dependency changes the sign up method is called
  useEffect(() => {
    if (isSuccess) {
      setLoading(false);
      signUp();
    }
  }, [isSuccess]);

  // handle image manipulations including uploading and setting
  const showFileInput = () => hiddenFileInput.current?.click();
  const handleImageUpload = (e: any) => {
    setDisplayedImage(URL.createObjectURL(e.target.files[0]));
    setUploadedImage(e.target.files[0]);
  };
  const clearImageUpload = () => setUploadedImage("");

  // handle  previous button
  const handlePrevious = () => setIndex(index - 1);

  // handle sign up functionality
  const handleSignup = (e: any) => {
    e.preventDefault();
    setLoading(true);
    // convert image file to string data from backend before proceeding to signup
    if (uploadedImage !== "") {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/image-upload`,
          { image: uploadedImage },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res: any) => {
          setFormData({ ...formData, image_url: res.data.url });
          setIsSuccess(true);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setError("Select an image for upload");
    }
  };

  return (
    <AuthLayout
      headingText={data.headingText}
      subText={data.subText}
      btnText={data.btnText}
      btnPath={data.btnPath}
    >
      <div className="photo-upload-container">
        <h1>Create Account</h1>
        <p className="desc-text">Step 3 of 3 : Upload image</p>
        <form onSubmit={handleSignup}>
          <div className="photo-upload">
            {!uploadedImage ? (
              <AddAPhotoOutlined fontSize="large" onClick={showFileInput} />
            ) : (
              <img src={displayedImage} alt="" width="100%" height="250px" />
            )}
          </div>
          {uploadedImage && (
            <Close
              className="remove-image"
              fontSize="medium"
              onClick={clearImageUpload}
            />
          )}
          <input
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            className="hidden-input"
            ref={hiddenFileInput}
            onChange={(e) => handleImageUpload(e)}
            required
          />
          {error && <p className="text-center error-text mt-3 mb-0">{error}</p>}
          <div className="sub-text">
            <p className="text1">
              This image will be displayed to the customers alongside your
              restaurant name.{" "}
            </p>
            <p className="text2">Recommended aspect ratio: 16:9</p>
          </div>
          <div className="mt-5">
            <MDBBtn className="back-btn btn btn-lg" onClick={handlePrevious}>
              BACK
            </MDBBtn>
            <MDBBtn className="next-btn" size="lg" type="submit">
              {loading || signupLoading ? (
                <Loader
                  height="27px"
                  width="27px"
                  borderTopColor="#FFAE0A"
                  borderTopWidth="5px"
                  borderWidth="5px"
                />
              ) : (
                "CREATE ACCOUNT"
              )}
            </MDBBtn>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};
