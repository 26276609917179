import { MDBBtn, MDBInputGroup } from "mdb-react-ui-kit";
import { AuthLayout } from "../../../../components";
import { useState } from "react";
import Loader from "../../../../components/loader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const data = {
  headingText: "Hello there!",
  subText:
    "Forgot your password? provide your email address in the textbox, a recovery link will be sent to you.",
  btnText: "BACK TO LOGIN",
  btnPath: "/restaurant-portal/signin",
};

export function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
  });

  const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const handleEmail = (e: any) => {
    const value = e.target.value;
    setEmail(value);
    if (value.match(validEmailRegex)) {
      setErrors({ ...errors, email: "" });
    } else {
      setErrors({ ...errors, email: "Invalid email" });
    }
  };

  const handleForgotPassword = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (email && !errors.email) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/restaurants/forgot-password`,
          {
            email: email.toLowerCase(),
          }
        );

        const { token } = response.data;
        toast.success("Reset code has been sent to your email");
        navigate("/restaurant-portal/verify-reset-code", {
          state: { email, forgotPasswordToken: token },
        });
      } catch (error: any) {
        if (error.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Failed to process request. Please try again.");
        }
        setLoading(false);
      }
    }
  };

  return (
    <AuthLayout
      headingText={data.headingText}
      subText={data.subText}
      btnText={data.btnText}
      btnPath={data.btnPath}
    >
      <div className="sign-in-container">
        <h1>Forgot Password</h1>
        <form className="mt-5" onSubmit={handleForgotPassword}>
          <MDBInputGroup size="lg">
            <input
              placeholder="Email"
              type="text"
              className="form-control"
              onChange={handleEmail}
              value={email}
              required
            />
          </MDBInputGroup>
          {errors.email && <small className="error-text">{errors.email}</small>}

          <MDBBtn className="signin-btn mt-5 w-100" size="lg" type="submit">
            {loading ? (
              <Loader
                height="27px"
                width="27px"
                borderTopColor="#FFAE0A"
                borderTopWidth="5px"
                borderWidth="5px"
              />
            ) : (
              "Recover Password"
            )}
          </MDBBtn>
        </form>
      </div>
    </AuthLayout>
  );
}
