/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction, useState } from "react";
import { checkbox_data } from "../pages/restaurant-portal/auth/data/data";
import "../assets/css/components.scss";
import { time_ } from "../utils/data/components";
import { toast } from "react-toastify";

export type BusinessHoursProps = {
  day: string;
  open: string;
  close: string;
};

type Props = {
  setModalDisplay: Dispatch<SetStateAction<boolean>>;
  setBusinessTime: Dispatch<SetStateAction<any>>;
};

export const TimeSelectionModal: React.FC<Props> = ({
  setModalDisplay,
  setBusinessTime,
}) => {
  const [checkedState, setCheckedState] = useState<
    { id: number | null; value: boolean }[]
  >(checkbox_data.map(() => ({ id: null, value: false })));
  const [selectedDay, setSelectedDay] = useState<{
    id: number | null;
    value: string;
  }>({ id: null, value: "" });
  const [openingTime, setOpeningTime] = useState<{
    id: number | null;
    value: string;
  }>({ id: null, value: "" });
  const [closingTime, setClosingTime] = useState<{
    id: number | null;
    value: string;
  }>({ id: null, value: "" });
  const [daytime, setDayTime] = useState<
    { day: string; open: string; close: string }[]
  >([]);
  const [error, setError] = useState(false);

  const isCurrentSelectionComplete = () => {
    return (
      selectedDay.id !== null &&
      openingTime.id === selectedDay.id &&
      closingTime.id === selectedDay.id
    );
  };

  const handleDay = (_arg: any, position: number) => {
    if (
      checkedState.some((state) => state.id !== null) &&
      !isCurrentSelectionComplete()
    ) {
      toast.error(
        "Complete the current day/time selection before proceeding to another."
      );
      setError(true);
      return;
    }
    const newCheckedState = checkedState.map((state, index) =>
      index === position ? { id: position, value: !state.value } : state
    );
    setCheckedState(newCheckedState);
    setSelectedDay({ id: position, value: _arg });

    if (openingTime?.id === position && closingTime.id === position) {
      const day = _arg;
      const close = closingTime.value;
      const open = openingTime.value;
      setDayTime((prevState) => [...prevState, { open, close, day }]);
    }
  };

  const handleOpeningTime = (_arg: string, position: number) => {
    setOpeningTime({ id: position, value: _arg });

    if (selectedDay?.id === position && closingTime.id === position) {
      const day = selectedDay.value;
      const close = closingTime.value;
      const open = _arg;

      // set opening time properties if it doesn't exist in the state else replace the existing item
      const dayArr = [...daytime];
      const index = dayArr.findIndex((item) => item.day === day);
      if (index !== -1) {
        dayArr[index] = { open, close, day };
        setDayTime(dayArr);
        setError(false);
      } else {
        setDayTime((prevState) => [...prevState, { open, close, day }]);
        setError(false);
      }
    }
  };

  const handleClosingTime = (_arg: any, position: number) => {
    setClosingTime({ id: position, value: _arg });

    if (selectedDay?.id === position && openingTime.id === position) {
      const day = selectedDay.value;
      const open = openingTime.value;
      const close = _arg;

      // set closing time properties if it doesn't exist in the state else replace the existing item
      const dayArr = [...daytime];
      const index = dayArr.findIndex((item) => item.day === day);
      if (index !== -1) {
        dayArr[index] = { open, close, day };
        setDayTime(dayArr);
        setError(false);
      } else {
        setDayTime((prevState) => [...prevState, { open, close, day }]);
        setError(false);
      }
    }
  };

  const handleProceed = () => {
    if (daytime.length < 1) {
      toast.error(
        "Please select at least a day with corresponding opening and closing times."
      );
      return;
    }
    setBusinessTime(daytime);
    setModalDisplay(false);
  };

  return (
    <div className="day-time-modal">
      <div className="day-time-modal-container">
        <button onClick={() => setModalDisplay(false)} className="close-btn">
          &times;
        </button>
        <div className="flex-box">
          <h2>Day & Time</h2>
          <p>
            Select at least 1 working day and the corresponding opening and
            closing time for each day
          </p>

          <div className="body">
            {checkbox_data.map((item, index: number) => (
              <div className="row" key={index}>
                <div className="day-input-group" key={item.id}>
                  <input
                    id={item.id}
                    type="checkbox"
                    value={item.id}
                    onChange={(e) => handleDay(e.target.value, index)}
                    checked={checkedState[index].value}
                    disabled={checkedState.some(
                      (state) =>
                        state.id !== null &&
                        state.id !== index &&
                        !isCurrentSelectionComplete()
                    )}
                  />
                  <label htmlFor={item.id}>{item.value}</label>
                </div>
                <div className="time-box">
                  <select
                    onChange={(e) => handleOpeningTime(e.target.value, index)}
                    disabled={checkedState[index].id !== index}
                  >
                    <option disabled selected>
                      -- select --
                    </option>
                    {time_.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <select
                    onChange={(e) => handleClosingTime(e.target.value, index)}
                    disabled={checkedState[index].id !== index}
                  >
                    <option disabled selected>
                      -- select --
                    </option>
                    {time_.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>

          {daytime.length >= 1 && (
            <div className="btn-container">
              <button
                className="add-restaurant-info-btn"
                onClick={handleProceed}
              >
                Proceed
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
