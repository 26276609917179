import { MDBBtn, MDBInputGroup } from "mdb-react-ui-kit";
import { AuthLayout } from "../../../../components";
import { useState } from "react";
import Loader from "../../../../components/loader";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const data = {
  headingText: "Hello there!",
  subText: "Enter the reset code sent to your email address.",
  btnText: "BACK TO LOGIN",
  btnPath: "/restaurant-portal/signin",
};

export function VerifyResetCode() {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, forgotPasswordToken } = location.state || {};
  const [resetCode, setResetCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleVerifyCode = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/restaurants/password/verify-code`,
        {
          code: resetCode,
        },
        {
          headers: {
            Authorization: `Bearer ${forgotPasswordToken}`,
          },
        }
      );

      const { token } = response.data;
      navigate("/restaurant-portal/reset-password", {
        state: { token, email },
      });
    } catch (error: any) {
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Invalid or expired code. Please try again.");
      }
      setLoading(false);
    }
  };

  if (!email || !forgotPasswordToken) {
    navigate("/restaurant-portal/forgot-password");
    return null;
  }

  return (
    <AuthLayout
      headingText={data.headingText}
      subText={data.subText}
      btnText={data.btnText}
      btnPath={data.btnPath}
    >
      <div className="sign-in-container">
        <h1>Verify Reset Code</h1>
        <form className="mt-5" onSubmit={handleVerifyCode}>
          <MDBInputGroup size="lg">
            <input
              placeholder="Enter reset code"
              type="text"
              className="form-control"
              onChange={(e) => setResetCode(e.target.value)}
              value={resetCode}
              required
            />
          </MDBInputGroup>

          <MDBBtn className="signin-btn mt-5 w-100" size="lg" type="submit">
            {loading ? (
              <Loader
                height="27px"
                width="27px"
                borderTopColor="#FFAE0A"
                borderTopWidth="5px"
                borderWidth="5px"
              />
            ) : (
              "Verify Code"
            )}
          </MDBBtn>
        </form>
      </div>
    </AuthLayout>
  );
}
