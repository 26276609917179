import { useState } from "react";
import { MDBBtn, MDBInputGroup } from "mdb-react-ui-kit";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AuthLayout } from "../../../../components";
import Loader from "../../../../components/loader";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const data = {
  headingText: "Hello there!",
  subText: "Enter your new password.",
  btnText: "BACK TO LOGIN",
  btnPath: "/restaurant-portal/signin",
};

export function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, email } = location.state || {};

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const handlePassword = (e: any) => {
    const value = e.target.value;
    setPassword(value);
    if (value.length > 7) {
      setErrors({ ...errors, password: "" });
    } else {
      setErrors({
        ...errors,
        password: "Password must be at least 8 characters long",
      });
    }
  };

  const handleConfirmPassword = (e: any) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value === password) {
      setErrors({ ...errors, confirmPassword: "" });
    } else {
      setErrors({ ...errors, confirmPassword: "Passwords do not match" });
    }
  };

  const handleResetPassword = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (password === confirmPassword && !errors.password) {
      try {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/restaurants/password/reset`,
          {
            new_password: password,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success("Password reset successful");
        navigate("/restaurant-portal/signin");
      } catch (error: any) {
        if (error.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Failed to reset password. Please try again.");
        }
        setLoading(false);
      }
    }
  };

  if (!token || !email) {
    navigate("/restaurant-portal/forgot-password");
    return null;
  }

  return (
    <AuthLayout
      headingText={data.headingText}
      subText={data.subText}
      btnText={data.btnText}
      btnPath={data.btnPath}
    >
      <div className="sign-in-container">
        <h1>Reset Password</h1>
        <form className="mt-5" onSubmit={handleResetPassword}>
          <MDBInputGroup className="password-group" size="lg">
            <input
              placeholder="New Password"
              type={showPassword ? "text" : "password"}
              className="form-control"
              onChange={handlePassword}
              value={password}
              required
            />
            <div onClick={() => setShowPassword((prev) => !prev)}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </div>
          </MDBInputGroup>
          {errors.password && (
            <small className="error-text">{errors.password}</small>
          )}

          <MDBInputGroup className="password-group mt-4" size="lg">
            <input
              placeholder="Confirm New Password"
              type={showConfirmPassword ? "text" : "password"}
              className="form-control"
              onChange={handleConfirmPassword}
              value={confirmPassword}
              required
            />
            <div onClick={() => setShowConfirmPassword((prev) => !prev)}>
              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
            </div>
          </MDBInputGroup>
          {errors.confirmPassword && (
            <small className="error-text">{errors.confirmPassword}</small>
          )}

          <MDBBtn className="signin-btn mt-5 w-100" size="lg" type="submit">
            {loading ? (
              <Loader
                height="27px"
                width="27px"
                borderTopColor="#FFAE0A"
                borderTopWidth="5px"
                borderWidth="5px"
              />
            ) : (
              "Reset Password"
            )}
          </MDBBtn>
        </form>
      </div>
    </AuthLayout>
  );
}
