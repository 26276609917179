/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { NavLink } from "react-router-dom";
import { PageLayout } from "../../../components/restaurant-portal/PageLayout";
import { ProfilePropsInterface } from "../restaurant.config";
import {
  useLazyGetBankDetailsQuery,
  useLazyGetRestaurantProfileQuery,
} from "../../../features/services/restaurant/service";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../app/store";
import Skeleton from "react-loading-skeleton";
import { toggleBankModal } from "../../../features/slice/restaurant/authSlice";

export const RestaurantProfile = () => {
  const dispatch = useDispatch();
  // const token = useSelector((state: RootState) => state?.restaurantAuth?.token);

  const [
    getRestaurantProfile,
    { data: profileDetails, isLoading, error, isSuccess },
  ] = useLazyGetRestaurantProfileQuery();

  const [getBankDetails, { data: bankDetails, isLoading: loading }] =
    useLazyGetBankDetailsQuery();

  useEffect(() => {
    getRestaurantProfile();
    getBankDetails();
  }, []);

  return (
    <PageLayout>
      <MDBContainer fluid={true} breakpoint="md" className="profile-container">
        <div className="nav-link">
          <h1>Profile</h1>
          <NavLink to="/restaurant-portal/profile/edit" className="edit-button">
            EDIT PROFILE
          </NavLink>
        </div>
        <MDBRow>
          <MDBCol size={12} md={6} className="col1">
            {profileDetails?.image_url ? (
              <img
                src={profileDetails?.image_url}
                alt={""}
                width="90%"
                height="230px"
              />
            ) : (
              <Skeleton width="90%" height="230px" />
            )}
            <div>
              <div className="bank-details-section mt-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h3>BANK DETAILS</h3>
                  <button
                    className="edit-button"
                    onClick={() => dispatch(toggleBankModal(true))}
                  >
                    EDIT BANK DETAILS
                  </button>
                </div>
                <div className="bank-info">
                  <div className="mb-3">
                    <p className="text-muted mb-1">Bank Name</p>
                    <p
                      className="fw-semibold"
                      style={{ textTransform: "capitalize" }}
                    >
                      {bankDetails?.bank_details?.bank || (
                        <Skeleton height={"24px"} width={"60%"} />
                      )}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-muted mb-1">Account Type</p>
                    <p
                      className="fw-semibold"
                      style={{ textTransform: "capitalize" }}
                    >
                      {bankDetails?.bank_details?.account_type || (
                        <Skeleton height={"24px"} width={"60%"} />
                      )}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-muted mb-1">Account Number</p>
                    <p className="fw-semibold">
                      {bankDetails?.bank_details?.account_number || (
                        <Skeleton height={"24px"} width={"60%"} />
                      )}
                    </p>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Account Name</p>
                    <p
                      className="fw-semibold"
                      style={{ textTransform: "capitalize" }}
                    >
                      {bankDetails?.bank_details?.account_name || (
                        <Skeleton height={"24px"} width={"60%"} />
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </MDBCol>
          <MDBCol size={12} md={6} className="col2">
            <div>
              <h3>RESTAURANT NAME</h3>
              <p style={{ textTransform: "capitalize" }}>
                {profileDetails?.name || (
                  <Skeleton height={"30px"} width={"60%"} />
                )}
              </p>
            </div>
            <div>
              <h3>RESTAURANT ADDRESS</h3>
              <p>
                {profileDetails?.address || (
                  <Skeleton height={"30px"} width={"60%"} />
                )}
              </p>
            </div>
            <div>
              <h3>CONTACT PHONE</h3>
              <p>
                {profileDetails?.phone || (
                  <Skeleton height={"30px"} width={"60%"} />
                )}
              </p>
            </div>
            <div>
              <h3>OPENING HOURS</h3>
              {profileDetails?.days_open?.map(
                (
                  item: { day: string; open: string; close: string },
                  i: number
                ) => (
                  <p key={i}>
                    <span style={{ textTransform: "capitalize" }}>
                      {item.day}
                    </span>
                    : {item.open}-{item.close}
                  </p>
                )
              ) || <Skeleton height={"30px"} width={"60%"} />}
            </div>
            <div></div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </PageLayout>
  );
};
