import {
  MDBModalBody,
  MDBModalHeader,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
} from "mdb-react-ui-kit";
import { Dispatch, SetStateAction } from "react";

import { currencyFormatter } from "../../utils/methods/methods";
import "./orderDetailsModal.scss";
// import Loader from "../loader";

interface OrderDetailProps {
  displayItem: OrderItem[];
  acceptOrder: (e: any) => void;
  rejectOrder: (e: any) => void;
  driver: RiderProps;
  showModal: boolean;
  setModalDisplay: Dispatch<SetStateAction<boolean>>;
  note: string;
  status: string;
  accept_loading: boolean;
  decline_loading: boolean;
}

type OrderItem = {
  id: string;
  name: string;
  image_url: string;
  price: number;
  qty: number;
};

type RiderProps = {
  name: string;
  photo: string;
  phone: string;
};

export const OrderDetailsModal = (props: OrderDetailProps | any) => {
  const {
    displayItem,
    acceptOrder,
    rejectOrder,
    accept_loading,
    decline_loading,
    driver,
    note,
    status,
    showModal,
    setModalDisplay,
  } = props;

  const OrderDetail = () => {
    return (
      <div className="order-details-modal">
        <div className="order-list">
          <ul>
            {displayItem?.order[0]?.map((item: any, id: number) => (
              <li key={id}>
                <img src={item?.meal?.image_url} alt={item?.meal?.name} />
                <div className="order-detail">
                  <div className="order-name-count">
                    <p className="order-name">{item?.meal?.name}</p>
                    <p>x{item?.qty}</p>
                  </div>
                  <div className="item-price">
                    {item?.meal?.price?.currency_code === "NGN" ? "₦" : "USD"}
                    {currencyFormatter(item?.meal?.price?.amount)}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {note && (
          <div className="customer-note">
            <h3>Customer Note</h3>
            <p>{note}</p>
          </div>
        )}
        {status === "pending" && (
          <div className="btn-container">
            <button
              className="btn accept-btn"
              onClick={(e: any) => acceptOrder(e)}
            >
              {accept_loading ? "ACCEPTING..." : "ACCEPT ORDER"}
            </button>
            <button
              className="btn decline-btn"
              onClick={(e: any) => rejectOrder(e)}
            >
              {decline_loading ? "DECLINING..." : "DECLINE ORDER"}
            </button>
          </div>
        )}
        {status === "delivered" && (
          <>
            <div className="rider-info-container">
              <h3>Rider Information</h3>
              <div className="rider-info">
                <div className="photo-container">
                  <img
                    src={driver?.image_url}
                    alt={driver?.first_name}
                    height="60px"
                    width="60px"
                  />
                </div>
                <div>
                  <h4>
                    {" "}
                    {driver?.first_name} {driver?.last_name}
                  </h4>
                  <p>{driver?.phone}</p>
                </div>
              </div>
            </div>
            <div className="btn-container">
              {/* <button className="btn accept-btn">MARK AS DELIVERED</button> */}
            </div>
          </>
        )}
        {(status === "enroute" || status === "preparing") && (
          <>
            <div className="rider-info-container">
              <h3>Rider Information</h3>
              <div className="rider-info">
                <div className="photo-container">
                  <img
                    src={driver?.image_url}
                    alt={driver?.first_name}
                    height="60px"
                    width="60px"
                  />
                </div>
                <div>
                  <h4>
                    {" "}
                    {driver?.first_name} {driver?.last_name}
                  </h4>
                  <p>{driver?.phone}</p>
                </div>
              </div>
            </div>
            <div className="btn-container">
              {/* <button className="btn accept-btn">MARK AS DELIVERED</button> */}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <MDBModal
      show={showModal}
      setShow={setModalDisplay}
      tabIndex="-1"
      className="order-details-modal"
    >
      <MDBModalDialog className="modal-dialog">
        <MDBModalContent className="modal-container">
          <MDBModalHeader>
            <h2>Order details</h2>
            <button
              className="close-btn"
              onClick={(e) => {
                e.preventDefault();
                setModalDisplay(false);
              }}
            >
              x
            </button>
          </MDBModalHeader>
          <MDBModalBody>
            <OrderDetail />
            {/* <h1>Waiting for a rider... for the modal</h1> */}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
